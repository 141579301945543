const { body } = document
// 2025/1/14 原本992，改1194讓ipad pro 4可以用m版
const WIDTH = 1194;
import { useAppStore } from '@/store/app'
export default function () {
  const appStore = useAppStore()
  let lastWidth = body.getBoundingClientRect().width;
  const $_isMobile = () => {
    const rect = body.getBoundingClientRect();
    // console.log('rect',rect.width-1,WIDTH)
    // console.log('rect.width - 1 < WIDTH',rect.width - 1 < WIDTH)
    return rect.width - 1 < WIDTH
  }
  const $_resizeHandler = () => {
    // 2025/1/14 改只偵測寬度resize，因上下滑動時，也會被偵測到，一直開闔
    const currentWidth = body.getBoundingClientRect().width;
    if (currentWidth !== lastWidth) {
      lastWidth = currentWidth; // 更新記錄的寬度

      const isMobile = $_isMobile();
      if (isMobile) {
        appStore.M_sidebar_opened(false);
      } else {
        appStore.M_sidebar_opened(true);
      }
    }
  }
  onBeforeMount(() => {
    window.addEventListener('resize', $_resizeHandler)
  })
  onMounted(() => {
    const isMobile = $_isMobile();
    if (isMobile) {
      appStore.M_sidebar_opened(false)
    } else {
      appStore.M_sidebar_opened(true)
    }
  })
  onBeforeUnmount(() => {
    window.removeEventListener('resize', $_resizeHandler)
  })
}
