<template>
  <div class="rounded-full shadow-md">
    <!-- Line Button -->
    <div
      ref="buttonRef"
      @click="toggleTooltip"
      @mouseenter="handleMouseEnter"
      class="inline-block cursor-pointer rounded-full bg-[#00C300] w-10 h-10 flex items-center justify-center"
    >
      <img src="@/icons/system/line.svg" alt="Line" class="w-30px h-30px" />
    </div>

    <!-- Tooltip -->
    <Transition name="tooltip-slide">
      <div
        v-if="showTooltip"
        ref="tooltipRef"
        class="absolute right-0 top-[-380px] h-[370px] w-[300px] overflow-hidden rounded-xl bg-white shadow-lg"
      >
        <div class="flex h-[50px] w-full items-center justify-between gap-3 bg-[#00C300] px-2">
          <div class="flex items-center">
            <img src="@/icons/system/line.svg" alt="Line" class="w-30px h-30px" />
            <span class="text-base text-white">Line</span>
          </div>

          <el-icon color="white" size="24px">
            <Close class="cursor-pointer" @click="closeTooltip" />
          </el-icon>
        </div>

        <div class="flex flex-col items-center mt-10px">
          <span class="text-md"> 掃描QRcode聯絡ReMo客服 </span>

          <img
            src="/assets/images/remo_branch_qrCode.png"
            alt="Coffee Home Category 1"
            width="100%"
            class="w-[270px] h-[270px] object-cover"
          />
          <a
            href="https://lin.ee/kOCQEHB"
            target="_blank"
            class="hover:text-primary hover:underline"
          >
            https://lin.ee/kOCQEHB
          </a>
        </div>
      </div>
    </Transition>
  </div>
</template>

<script setup>
import { ref, onMounted, onUnmounted } from 'vue'
import { Close } from '@element-plus/icons-vue'

const showTooltip = ref(false)
const isMobile = ref(false)
const buttonRef = ref(null)
const tooltipRef = ref(null)

// 檢測是否為行動裝置
const checkMobile = () => {
  isMobile.value = window.innerWidth <= 768
}

// 處理點擊事件
const toggleTooltip = () => {
  if (isMobile.value) {
    showTooltip.value = !showTooltip.value
  }
}

// 處理滑鼠事件 (桌面版)
const handleMouseEnter = () => {
  if (!isMobile.value) {
    showTooltip.value = true
  }
}

const closeTooltip = () => {
  showTooltip.value = false
}

// 處理點擊外部關閉
const handleClickOutside = (event) => {
  if (
    showTooltip.value &&
    tooltipRef.value &&
    !tooltipRef.value.contains(event.target) &&
    buttonRef.value &&
    !buttonRef.value.contains(event.target)
  ) {
    showTooltip.value = false
  }
}

// 生命週期鉤子
onMounted(() => {
  checkMobile()
  window.addEventListener('resize', checkMobile)
  document.addEventListener('mousedown', handleClickOutside)
  document.addEventListener('touchstart', handleClickOutside)
})

onUnmounted(() => {
  window.removeEventListener('resize', checkMobile)
  document.removeEventListener('mousedown', handleClickOutside)
  document.removeEventListener('touchstart', handleClickOutside)
})
</script>

<style scoped>
.tooltip-slide-enter-active,
.tooltip-slide-leave-active {
  transition: all 0.3s ease-out;
}

.tooltip-slide-enter-from,
.tooltip-slide-leave-to {
  opacity: 0;
  transform: translateY(20px);
}
</style>
